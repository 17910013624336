import React, { useContext } from "react";
import { Grid, Hidden } from "@mui/material";
import Header from "./components/header";
import Form from "./section/form";
import "./theme/index.css";
import Summary from "./section/summary";
import { SmartHomeCalculatorContext } from "./context";
import UserDetails from "./section/user-details";
import VerifyOtp from "./section/verify-otp";
import Estimation from "./section/estimation";
import SmartHomeCapabilities from "./section/smart-home-capabilities";
import HomeTypes from "./section/types-of-home";
import CompletionDate from "./section/completion-date";
import LoadingScreen from "./section/loading-screen";

function App() {
  const {
    activeStep,
    updateActiveStep,
    userResponse,
    updateUserResponse,
    updateShowFinalEstimationScreen,
    shouldShowFinalEstimation,
    showLoadingScreen,
    updateShowLoadingScreen,
    showVerifyOtpPage,
    updateShowVerifyOtpPage,
  } = useContext(SmartHomeCalculatorContext);
  return (
    <div className="container">
      <Header activeStep={activeStep + 1} />
      {showLoadingScreen ? (
        <Grid item xs={12} md={12} className={`form-container`}>
          <LoadingScreen
            phoneNumber={userResponse["user-details"]["phone-no"]}
            updateShowFinalEstimationScreen={updateShowFinalEstimationScreen}
            updateShowLoadingScreen={updateShowLoadingScreen}
            updateShowVerifyOtpPage={updateShowVerifyOtpPage}
          />
        </Grid>
      ) : shouldShowFinalEstimation ? (
        <Grid item xs={12} md={12} className={`form-container`}>
          <Estimation />
        </Grid>
      ) : (
        <Grid container spacing={3} className="body-container">
          <Grid
            item
            xs={12}
            md={8}
            className={`form-container ${
              activeStep === 5
                ? "background-image-container"
                : activeStep === 6
                ? "verify-otp-container"
                : ""
            }`}
          >
            {activeStep === 0 && (
              <SmartHomeCapabilities
                activeStep={activeStep}
                updateActiveStep={updateActiveStep}
                userResponse={userResponse}
                updateUserResponse={updateUserResponse}
              />
            )}
            {activeStep === 1 && (
              <HomeTypes
                activeStep={activeStep}
                updateActiveStep={updateActiveStep}
                userResponse={userResponse}
                updateUserResponse={updateUserResponse}
              />
            )}
            {activeStep > 1 && activeStep < 4 && (
              <Form
                activeStep={activeStep}
                updateActiveStep={updateActiveStep}
                userResponse={userResponse}
                updateUserResponse={updateUserResponse}
              />
            )}
            {activeStep === 4 && (
              <CompletionDate
                activeStep={activeStep}
                updateActiveStep={updateActiveStep}
                userResponse={userResponse}
                updateUserResponse={updateUserResponse}
              />
            )}
            {activeStep === 5 && (
              <UserDetails
                activeStep={activeStep}
                updateActiveStep={updateActiveStep}
                userResponse={userResponse}
                updateUserResponse={updateUserResponse}
                updateShowLoadingScreen={updateShowLoadingScreen}
              />
            )}
            {showVerifyOtpPage && (
              <VerifyOtp
                activeStep={activeStep}
                updateActiveStep={updateActiveStep}
                userResponse={userResponse}
                updateShowFinalEstimationScreen={
                  updateShowFinalEstimationScreen
                }
                updateShowVerifyOtpPage={updateShowVerifyOtpPage}
              />
            )}
          </Grid>
          <Hidden smDown>
            <Summary userResponse={userResponse} activeStep={activeStep} />
          </Hidden>
        </Grid>
      )}
    </div>
  );
}

export default App;
