import * as React from "react";
import {
  Grid,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const DateInput = ({ id, value, onChange }) => (
  <Grid item xs={12} md={6} key={id}>
  <LocalizationProvider
    dateAdapter={AdapterDayjs}
    adapterLocale="en-gb"
  >
    <DatePicker
      slotProps={{
        inputAdornment: {
          position: "start",
        },
      }}
      sx={{
        width: "100%",
        borderColor: "#667085",
        color: "#667085",
        ".Mui-error": {
          fieldset: {
            borderColor: "currentcolor !important",
          },
        },
        "& fieldset": {
          borderColor: "#667085 !important",
        },
        "& .MuiInputBase-input::placeholder": {
          color: "#667085",
          opacity: 1,
        },
        "&.Mui-focused": {
          color: "#0fabbb !important",
          borderColor: "#0fabbb !important",
          backgroundColor: "#f1f9fc !important",
          outlineColor: "#0fabbb !important",
          "& fieldset": {
            borderColor: "#0fabbb !important",
          },
        },
        ":hover": {
          color: "#0fabbb",
          borderColor: "#0fabbb",
          backgroundColor: "#f1f9fc",
          outlineColor: "#0fabbb",
          "& fieldset": {
            borderColor: "#0fabbb !important",
          },
        },
      }}
      onChange={onChange}
      value={value}
    />
  </LocalizationProvider>
</Grid>
);

export { DateInput };
