import { FormControl, Grid } from "@mui/material";
import { QuesList } from "../data";

import FormFooter from "../components/form-footer";
import { QuestionText, QuestionHelperText } from "../components/question-text";
import { FormInput } from "../components/form-input";

const Form = ({
  activeStep,
  updateActiveStep,
  userResponse,
  updateUserResponse,
}) => {
  const { question, label, options, id } = QuesList[activeStep];

  return (
    <FormControl className="question-form">
      <QuestionText text={question} />
      <QuestionHelperText text={label} />
      <Grid container spacing={2.5}>
        {options.map((card) => {
          const selectedClass = userResponse[id] === card.id ? "selected" : "";
          return (
            <FormInput
              key={card.id}
              id={card.id}
              selectedClass={selectedClass}
              label={card.text}
              helperLabel={card.subText}
              onClick={() => {
                userResponse[id] = card.id;
                updateUserResponse({ ...userResponse });
                updateActiveStep(activeStep + 1);
              }}
            />
          );
        })}
      </Grid>
      <FormFooter activeStep={activeStep} updateActiveStep={updateActiveStep} />
    </FormControl>
  );
};

export default Form;
