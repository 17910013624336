import { FormControl, Grid } from "@mui/material";
import { QuesList } from "../data";

import "dayjs/locale/en-gb";
import FormFooter from "../components/form-footer";
import { QuestionText, QuestionHelperText } from "../components/question-text";
import { FormInput } from "../components/form-input";
import { DateInput } from "../components/date-input";
import { convertDate, getDate } from "../utils/date";

const CompletionDate = ({
  activeStep,
  updateActiveStep,
  userResponse,
  updateUserResponse,
}) => {
  const { question, label, options, id } = QuesList[activeStep];
  return (
    <FormControl className="question-form">
      <QuestionText text={question} />
      <QuestionHelperText text={label} />
      <Grid container spacing={2.5}>
        {options.map((card) => {
          const selectedClass = userResponse[id] === card.id ? "selected" : "";
          return card.id !== "custom" ? (
            <FormInput
              key={card.id}
              id={card.id}
              selectedClass={selectedClass}
              onClick={() => {
                userResponse[id] = { type: card.id, date: getDate(card.id) };
                updateUserResponse({ ...userResponse });
                updateActiveStep(activeStep + 1);
              }}
              label={card.text}
              helperLabel={card.subText}
            />
          ) : (
            <DateInput
              key={card.id}
              id={card.id}
              onChange={(e) => {
                userResponse[id] = {
                  type: card.id,
                  date: getDate(e),
                };
                updateUserResponse({ ...userResponse });
                updateActiveStep(activeStep + 1);
              }}
              value={
                userResponse["completion-date"].date
                  ? convertDate(userResponse["completion-date"].date)
                  : ""
              }
            />
          );
        })}
      </Grid>
      <FormFooter activeStep={activeStep} updateActiveStep={updateActiveStep} />
    </FormControl>
  );
};

export default CompletionDate;
