
import dayjs from "dayjs";

export const convertDate = (dateString) => {
  const parts = dateString.split("/");
  const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
  return dayjs(new Date(formattedDate));
};

export const getDate = (val) => {
  let d = new Date();
  switch (val) {
    case "one-month":
      d.setMonth(d.getMonth() + 1);
      break;
    case "six-month":
      d.setMonth(d.getMonth() + 6);
      break;
    case "one-year":
      d.setMonth(d.getMonth() + 12);
      break;
    default:
      d = new Date(val);
      break;
  }
  return d.toLocaleDateString();
};
