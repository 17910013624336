import * as React from 'react';
import MobileStepper from '@mui/material/MobileStepper';

export default function ProgressMobileStepper({ activeStep}) {

  return (
    <MobileStepper
      variant="progress"
      steps={8}
      position="static"
      activeStep={activeStep}
      className='mobile-stepper'
    />
  );
}
