import { Box, FormControl, Typography, Grid, Card } from "@mui/material";
import { BLACK_SHADE } from "../theme/constants";
import { getIcon } from "../utils/getIcon";
import { QuesList } from "../data";
import FormFooter from "../components/form-footer";
import { useState } from "react";
import { QuestionText, QuestionHelperText } from "../components/question-text";
import { CustomInput } from "../components/custom-input";

const HomeTypes = ({
  activeStep,
  updateActiveStep,
  userResponse,
  updateUserResponse,
}) => {
  const { question, label, options, id } = QuesList[activeStep];
  const [subButtons, setSubButtons] = useState("");

  return (
    <FormControl className="question-form">
      <QuestionText text={question} />
      <QuestionHelperText text={label} />
      <Grid container spacing={2.5}>
        {options.map((card) => {
          return card.id !== "custom" ? (
            <Grid item xs={12} md={6} key={card.id}>
              <Card
                variant="outlined"
                className={`option-card`}
                onClick={() => {
                  setSubButtons(card.id);
                }}
              >
                <Box className="option-card-text">
                  <Box className="option-card-text">
                    {getIcon(card.id, BLACK_SHADE, "24px", "white")}
                    {card.text}
                  </Box>
                </Box>
                {card.id === subButtons ||
                card.id === userResponse[id]?.type ? (
                  <Box sx={{ display: "flex", gap: 2, mt: 1 }}>
                    {card.buttons.map((item) => {
                      const selectedClass =
                        userResponse[id].type === card.id &&
                        userResponse[id].size === item.id
                          ? "selected"
                          : "";
                      return (
                        <Card
                          key={item.id}
                          variant="outlined"
                          className={`option-card ${selectedClass}`}
                          onClick={() => {
                            userResponse[id] = {
                              type: card.id,
                              size: item.id,
                              sqft: item.subText
                            };
                            updateUserResponse({ ...userResponse });
                            updateActiveStep(2);
                          }}
                        >
                          <Box className="option-card-text">
                            <Box className="option-card-text">{item.text}</Box>
                            {selectedClass === "selected"
                              ? getIcon("checked")
                              : null}
                          </Box>
                          <Typography variant="caption">
                            {item.subText}
                          </Typography>
                        </Card>
                      );
                    })}
                  </Box>
                ) : null}
              </Card>
            </Grid>
          ) : (
            <CustomInput
              key={card.id}
              id={card.id}
              label={card.text}
              defaultValue={
                userResponse["home-size"].type === "custom"
                  ? userResponse["home-size"].size
                  : ""
              }
              onBlur={(e) => {
                if (e.target.value) {
                  userResponse[id] = {
                    type: card.id,
                    size: e.target.value,
                  };
                  updateUserResponse({ ...userResponse });
                  updateActiveStep(2);
                }
              }}
            />
          );
        })}
      </Grid>
      <FormFooter activeStep={activeStep} updateActiveStep={updateActiveStep} />
    </FormControl>
  );
};

export default HomeTypes;
