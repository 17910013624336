import { ReactComponent as SMART_LIGHTING } from "../assets/smart-light.svg";
import { ReactComponent as VOICE_COMMAND } from "../assets/voice-command.svg";
import { ReactComponent as HOME_SECURITY } from "../assets/home-security.svg";
import { ReactComponent as CUSTOM_HOME } from "../assets/custom.svg";
import { ReactComponent as MULTIMEDIA } from "../assets/multimedia.svg";
import { ReactComponent as ACCIDENT_PROTECTION } from "../assets/accident-protection.svg";
import { ReactComponent as CLIMATE_CONTROL } from "../assets/climate-control.svg";
import { ReactComponent as SURVEILLANCE } from "../assets/surveillance.svg";
import { ReactComponent as FLEXIBLE } from "../assets/flexible.svg";
import { ReactComponent as PREMIUM } from "../assets/premium.svg";
import { ReactComponent as HIGHEND } from "../assets/high-end.svg";
import { ReactComponent as CHECKED } from "../assets/checked.svg";
import { ReactComponent as FLOOR_PLAN } from "../assets/floor-plan.svg";
import { ReactComponent as UNDER_CONSTRUCTION } from "../assets/under-construction.svg";
import { ReactComponent as CONSTRUCTED } from "../assets/constructed.svg";
import { ReactComponent as MOVED_IN } from "../assets/moved-in.svg";
import { ReactComponent as ONE_BHK } from "../assets/1BHK.svg";
import { ReactComponent as TWO_BHK } from "../assets/2BHK.svg";
import { ReactComponent as THREE_BHK } from "../assets/3BHK.svg";
import { ReactComponent as FOUR_BHK } from "../assets/4BHK.svg";
import { ReactComponent as GMAIL } from "../assets/gmail.svg";
import { ReactComponent as WHATSAPP_LOGO } from "../assets/logos_whatsapp.svg";


export const getIcon = (name, strokeColor, size, fillColor) => {
  switch (name) {
    case "smart-lighting":
      return <SMART_LIGHTING id={name} style={{ stroke: strokeColor }} />;
    case "voice-command":
      return <VOICE_COMMAND id={name} style={{ fill: strokeColor }} />;
    case "home-security":
      return (
        <HOME_SECURITY
          id={name}
          style={{ stroke: strokeColor, fill: strokeColor }}
        />
      );
    case "multimedia-control":
      return (
        <MULTIMEDIA
          id={name}
          style={{ stroke: strokeColor, fill: strokeColor }}
        />
      );
    case "accident-protection":
      return (
        <ACCIDENT_PROTECTION
          id={name}
          style={{ stroke: strokeColor, fill: strokeColor }}
        />
      );
    case "climate-control":
      return <CLIMATE_CONTROL id={name} style={{ stroke: strokeColor }} />;
    case "surveillance":
      return <SURVEILLANCE id={name} style={{ stroke: strokeColor }} />;
    case "summary-home":
      return (
        <CUSTOM_HOME
          id={name}
          style={{ stroke: strokeColor }}
          height={size}
          width={size}
        />
      );
    case "flexible-economical":
      return <FLEXIBLE id={name} style={{ stroke: strokeColor }} />;
    case "premium-robust":
      return <PREMIUM id={name} style={{ stroke: strokeColor }} />;
    case "high-end-futuristic":
      return <HIGHEND id={name} style={{ stroke: strokeColor }} />;
    case "checked":
      return <CHECKED id={name} height="24px" width="24px" />;
    case "floor-plan":
      return <FLOOR_PLAN id={name} style={{ stroke: strokeColor }} />;
    case "under-construction":
      return <UNDER_CONSTRUCTION id={name} style={{ stroke: strokeColor }} />;
    case "constructed":
      return <CONSTRUCTED id={name} style={{ stroke: strokeColor }} />;
    case "moved-in":
      return (
        <MOVED_IN id={name} style={{ stroke: strokeColor, fill: fillColor }} />
      );
    case "1bhk":
      return (
        <ONE_BHK id={name} style={{ stroke: strokeColor, fill: fillColor }} />
      );
    case "2bhk":
      return (
        <TWO_BHK id={name} style={{ stroke: strokeColor, fill: fillColor }} />
      );
    case "3bhk":
      return (
        <THREE_BHK id={name} style={{ stroke: strokeColor, fill: fillColor }} />
      );
    case "4bhk":
      return (
        <FOUR_BHK id={name} style={{ stroke: strokeColor, fill: fillColor }} />
      );
    case "one-month":
      return (
        <ONE_BHK id={name} style={{ stroke: strokeColor, fill: fillColor }} />
      );
    case "six-month":
      return (
        <TWO_BHK id={name} style={{ stroke: strokeColor, fill: fillColor }} />
      );
    case "one-year":
      return (
        <THREE_BHK id={name} style={{ stroke: strokeColor, fill: fillColor }} />
      );
    case "custom":
      return (
        <FOUR_BHK id={name} style={{ stroke: strokeColor, fill: fillColor }} />
      );
    case "whatsapp-logo":
      return <WHATSAPP_LOGO id={name} style={{ height: "15px", stroke: strokeColor, fill: fillColor}} />;
    case "gmail":
      return <GMAIL id={name} style={{ height: "20px"}} />;
    default:
      return null;
  }
};
