import { createContext } from "react";
import { useState } from "react";

const defaultContext = {
  activeStep: 0,
  userResponse: {
    "your-smart-home": [],
    "home-size": {
      type: "",
      size: "",
      sqft: ""
    },
    "solution-type": "",
    "home-stage": "",
    "completion-date": "",
    "user-details": {},
  },
  shouldShowFinalEstimation: false,
  showLoadingScreen: false,
  showVerifyOtpPage: false
};
export const SmartHomeCalculatorContext = createContext(defaultContext);

export const CalculatorProvider = ({ children }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [shouldShowFinalEstimation, setShouldShowFinalEstimation] = useState(false);
  const [showLoadingScreen, setShowLoadingScreen] = useState(false)
  const [showVerifyOtpPage, setShowVerifyOtpPage] = useState(false)

  const [userResponse, setUserResponse] = useState({
    "your-smart-home": [],
    "home-size": "",
    "solution-type": "",
    "home-stage": "",
    "completion-date": "",
    "user-details": {},
  });

  const updateActiveStep = (step) => setActiveStep(step);

  const updateUserResponse = (userResponse) => setUserResponse(userResponse);

  const updateShowFinalEstimationScreen = (showFinalEstimation) => setShouldShowFinalEstimation(showFinalEstimation);

  const updateShowLoadingScreen = (showLoadingScreen) => setShowLoadingScreen(showLoadingScreen)

  const updateShowVerifyOtpPage = (showVerifyOtpPage) => setShowVerifyOtpPage(showVerifyOtpPage)
  
  return (
    <SmartHomeCalculatorContext.Provider
      value={{
        activeStep,
        updateActiveStep,
        userResponse,
        updateUserResponse,
        updateShowFinalEstimationScreen,
        shouldShowFinalEstimation,
        showLoadingScreen,
        updateShowLoadingScreen,
        updateShowVerifyOtpPage,
        showVerifyOtpPage
      }}
    >
      {children}
    </SmartHomeCalculatorContext.Provider>
  );
};
