import msg91 from "msg91";

// Initialize Msg91
const authKey = "240641AjWMHbic3kQ65e82995P1";
const otpTemplateId = '65e82b99d6fc0557cf00bb42';
msg91.initialize({ authKey });

const handleResponse = async (response) => {
  const data = await response.json();
  if (response.ok) {
    return { type: "success", data };
  } else {
    return { type: "error", status: response.status };
  }
};

export const verifyPhoneNumber = async (phoneNumber) => {
  try {
    const response = await fetch(
      "https://uedqetflgyjjg4vvhskhgwtxvi0dpmxg.lambda-url.eu-north-1.on.aws/",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ mobile_number: phoneNumber }),
      }
    );
    return handleResponse(response);
  } catch (error) {
    console.error("Error verifying phone number:", error);
    throw error;
  }
};

export const handleEstimation = async (answers) => {
  try {
    const response = await fetch(
      "https://d67ucrexrlyvojuccqlb5lmw3i0spcfn.lambda-url.eu-north-1.on.aws/",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(answers),
      }
    );
    return handleResponse(response);
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const sendOrResendOtp = (method, mobileNumber) => {
  const otp = msg91.getOTP(otpTemplateId, { length: 6 });
  return otp[method](mobileNumber)
    .then(response => {
      console.log(`OTP ${method === 'send' ? 'sent' : 'resent'} successfully`, response);
      return response;
    })
    .catch(error => {
      console.error(`Error ${method === 'send' ? 'sending' : 'resending'} OTP`, error);
      throw error;
    });
};

export const sendOtp = (mobileNumber) => {
  return sendOrResendOtp('send', mobileNumber);
};

export const resendOtp = (mobileNumber) => {
  return sendOrResendOtp('retry', mobileNumber);
};

export const verifyOtp = (mobileNumber, otpInput) => {
  // Simulate a successful OTP verification response
  const response = { type: "success", message: "OTP verified successfully" };
  console.log(response.message, response);
  return Promise.resolve(response);
};