import { FormControl, Typography, Box, Button } from "@mui/material";
import EstimationReport from "../assets/estimation-report.png";
import { getIcon } from "../utils/getIcon";

const Estimation = () => {
  return (
    <FormControl
      className="user-form"
      sx={{
        width: "50% !important"
      }}
    >
      <img
        src={EstimationReport}
        height={250}
        width={250}
        style={{ alignSelf: "center" }}
        alt={"Smart home estimation"}
      />
      <Typography className="estimation-text">
        Your smart home estimate has been generated. ✅
      </Typography>
      <Typography className="estimation-helper-text">
        Based on the details that you’ve shared with us, we estimated the cost
        to be between <span>₹54,300 - ₹84,300.</span>
      </Typography>
      <Typography className="estimation-sub-helper-text">
        We’ve shared a detailed estimate with you, which will help you
        understand the product, technology & installation details.
      </Typography>
      <Box className="icon-button-wrapper" sx={{ display: "flex", justifyContent: "center", gap: "20px" }}>
        <Button className="icon-button" startIcon={getIcon("gmail")} variant="outlined"  onClick={() => window.open('mailto:', '_blank')}>Open Gmail</Button>
        <Button className="icon-button" startIcon={getIcon("whatsapp-logo")} variant="outlined"  onClick={() => window.open('https://wa.me/917949121212', '_blank')}>Open Whatsapp</Button>
      </Box>
    </FormControl>
  );
};

export default Estimation;
