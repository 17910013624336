import { AppBar, Hidden, Toolbar, Typography } from "@mui/material";
import React from "react";
import { BLACK } from "../theme/constants";
import logo from "../assets/logo.png";
import ProgressMobileStepper from "./stepper";

const Header = ({ activeStep }) => {
  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "unset", boxShadow: "unset" }}
    >
      <Toolbar>
        <img
          src={logo}
          alt="Smartify"
          height={24}
          style={{ margin: "0 8px " }}
        />
        <Typography
          className="header-text"
          sx={{
            color: BLACK,
          }}
        >
          SMARTIFY
        </Typography>
      </Toolbar>
      <Hidden smDown>
        <ProgressMobileStepper activeStep={activeStep} />
      </Hidden>
    </AppBar>
  );
};
export default Header;
