import * as React from "react";
import { Typography } from "@mui/material";

const QuestionText = ({ text }) => (
  <Typography className="question-text">{text}</Typography>
);

const QuestionHelperText = ({ text }) => (
  <Typography className="question-helper-text">{text}</Typography>
);

export { QuestionText, QuestionHelperText };
