import React, { useState, useRef } from "react";
import { TextField, Grid } from "@mui/material";

function OTPInput() {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputsRef = [useRef(null), useRef(null), useRef(null), useRef(null)];

  const handleChange = (element, index) => {
    const value = element.value;
    const newOtp = [...otp];
    newOtp[index] = value.substring(value.length - 1, value.length);

    if (value.length > 0 && index < 3) {
      inputsRef[index + 1].current.focus();
    }

    setOtp(newOtp);
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{ width: "100%", m: 0, p: "8px" }}
    >
      {otp.map((data, index) => (
        <Grid item key={index} sx={{ width: "20%" }}>
          <TextField
            inputRef={inputsRef[index]}
            type="tel"
            value={data}
            onChange={(e) => handleChange(e.target, index)}
            inputProps={{
              maxLength: 1,
              style: { textAlign: "center" },
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default OTPInput;
