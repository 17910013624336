import * as React from "react";
import { Box, Typography, Grid, Card } from "@mui/material";
import { BLACK_SHADE } from "../theme/constants";
import { getIcon } from "../utils/getIcon";

const FormInput = ({ id, selectedClass, onClick, label, helperLabel }) => (
  <Grid item xs={12} md={6} key={id}>
    <Card
      variant="outlined"
      className={`option-card ${selectedClass}`}
      onClick={onClick}
    >
      <Box className="option-card-text">
        <Box className="option-card-text">
          {getIcon(id, BLACK_SHADE, "24px", "white")}
          {label}
        </Box>
        {selectedClass === "selected" ? getIcon("checked") : null}
      </Box>
      {helperLabel ? (
        <Typography variant="caption">{helperLabel}</Typography>
      ) : null}
    </Card>
  </Grid>
);

export { FormInput };
