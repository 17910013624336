import React from "react";
import {
    Button,
    Box,
  } from "@mui/material";
  import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { BackButton } from "./back-button";


const FormFooter = ({ activeStep, updateActiveStep }) => {
  return (
    <Box className="form-action-panel">
      <BackButton
        disabled={activeStep === 0}
        onClick={() => updateActiveStep(activeStep - 1)}
      />
      <Button
        variant="contained"
        className="continue-btn"
        endIcon={<ArrowForwardIcon />}
        disabled={activeStep === 7}
        onClick={() => updateActiveStep(activeStep + 1)}
      >
        Continue
      </Button>
    </Box>
  );
};
export default FormFooter;
